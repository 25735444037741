import toast from "react-hot-toast";
import { server } from "../store";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";

export const getAllCompany =
  (email = "", keyword = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "allCompanyRequest" });
      const token = getAuthToken();

      if (!token) {
        throw new Error("User not logged in");
      }

      const { data } = await axios.get(
        `${server}/admin/getallcompany?keyword=${keyword}&email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      dispatch({ type: "allCompanySuccess", payload: data.comapny });
      toast.success(data.message);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      dispatch({
        type: "allCompanyFail",
        payload: errorMessage,
      });
      toast.error(errorMessage);
    }
  };
export const deleteCompany = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteCompanyRequest" });
    const token = getAuthToken();

    const { data } = await axios.delete(`${server}/deletecompany/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    dispatch({ type: "deleteCompanySuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    dispatch({
      type: "deleteCompanyFail",
      payload: error.response.data.message,
    });
  }
};

export const registerCompany = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "RegisterCompanyRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/registerCompany`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    dispatch({ type: "RegisterCompanySuccess", payload: data.message });
    toast.success(data.message);
    return data.companyId;
  } catch (error) {
    dispatch({
      type: "RegisterCompanyFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const registerspvvCompany =
  (formData, companyId) => async (dispatch) => {
    try {
      dispatch({ type: "RegisterspvvCompanyRequest" });
      const token = getAuthToken();
      const { data } = await axios.post(
        `${server}/registerspvvCompany/${companyId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      dispatch({ type: "RegisterspvvCompanySuccess", payload: data.message });
      toast.success(data.message);
      return data.success;
    } catch (error) {
      dispatch({
        type: "RegisterspvvCompanyFail",
        payload: error.response.data.message,
      });
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

export const addDirectors = (formData, companyid) => async (dispatch) => {
  try {
    dispatch({ type: "AddDirectorsRequest" });
    const token = getAuthToken();
    console.log("id", companyid);
    const { data } = await axios.post(
      `${server}/addDirector/${companyid}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "AddDirectorsSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "AddDirectorsFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};
export const addSpvDirectors = (formData, companyid) => async (dispatch) => {
  try {
    dispatch({ type: "AddDirectorsRequest" });
    const token = getAuthToken();
    console.log("id", companyid);
    const { data } = await axios.post(
      `${server}/addSpvDirector/${companyid}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "AddDirectorsSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "AddDirectorsFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

export const addNominees = (formData, companyid) => async (dispatch) => {
  try {
    dispatch({ type: "AddNomineesRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/addNominee/${companyid}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "AddNomineesSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({ type: "AddNomineesFail", payload: error.response.data.message });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const addSpvNominees = (formData, companyid) => async (dispatch) => {
  try {
    dispatch({ type: "AddNomineesRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/addNominee/${companyid}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "AddNomineesSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({ type: "AddNomineesFail", payload: error.response.data.message });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export const uploadSpvDocuments = (formData, CompanyId) => async (dispatch) => {
  try {
    dispatch({ type: "UploadSpvDocumentsRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/addspvDocuments/${CompanyId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UploadSpvDocumentsSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "UploadSpvDocumentsFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};
export const uploadDocuments = (formData, CompanyId) => async (dispatch) => {
  try {
    dispatch({ type: "UploadDocumentsRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/addDocuments/${CompanyId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UploadDocumentsSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "UploadDocumentsFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

export const addContract = (formData, compnayid) => async (dispatch) => {
  try {
    dispatch({ type: "AddContractRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/addContract/${compnayid}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "AddContractSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({ type: "AddContractFail", payload: error.response.data.message });
    toast.error(error.response.data.message);
  }
};

export const handlePayment = (payment, companyId) => async (dispatch) => {
  try {
    dispatch({ type: "HandlePaymentRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/addpayment/${companyId}`,
      payment,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "HandlePaymentSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "HandlePaymentFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

export const deleteCompanyDoc = (CompanyId, DocId) => async (dispatch) => {
  try {
    dispatch({ type: "deleteDocRequest" });
    const token = getAuthToken();
    const { data } = await axios.delete(
      `${server}/deleteCompanyDoc?companyId=${CompanyId}&docsId=${DocId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(data);
    dispatch({ type: "deleteDocSuccess", payload: data.message });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "deleteDocFail",
      payload: error.response.data.message,
    });
  }
};

export const ViewDocuments = (key) => async (dispatch) => {
  try {
    dispatch({ type: "ViewDocumentsRequest" });
    const token = getAuthToken();
    console.log(key);

    const { data } = await axios.post(
      `${server}/generatePresignedUrl`,
      { key },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "ViewDocumentsSuccess", payload: data.message });
    console.log(data);

    toast.success(data.message);
    console.log("url", data.url);
    return data.url;
  } catch (error) {
    dispatch({
      type: "ViewDocumentsFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

export const companylogin = (email, password, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "companyloginRequest" });

    const { data } = await axios.post(
      `${server}/companylogin`,
      { email, password }, // Pass recaptchaToken in the request body
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({ type: "companyloginSuccess", payload: data });
    toast.success("Please Send Otp to email");
    navigate("/sendotp", { state: { email } });
  } catch (error) {
    dispatch({
      type: "companyloginFail",
      payload: error.response.data.message,
    });
    toast.error(
      error.response.data.message || "Login failed. Please try again."
    );
  }
};
export const Individualogin =
  (email, password, navigate) => async (dispatch) => {
    try {
      dispatch({ type: "individualloginRequest" });

      const { data } = await axios.post(
        `${server}/individuallogin`,
        { email, password }, // Pass recaptchaToken in the request body
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      dispatch({ type: "individualloginSuccess", payload: data });
      toast.success("Please Send Otp to email");
      navigate("/sendotp", { state: { email } });
    } catch (error) {
      dispatch({
        type: "individualloginFail",
        payload: error.response.data.message,
      });
      toast.error(
        error.response.data.message || "Login failed. Please try again."
      );
    }
  };
export const SendOtp = (email, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "OtpSendRequest" });

    const { data } = await axios.post(
      `${server}/sendOtp`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({ type: "OtpSendSuccess", payload: data });
    navigate("/Authenticate/email", { state: { email } }); // Pass phoneNumber in the state
    toast.success(`Otp is sent to your Registered email ${email}`);
  } catch (error) {
    dispatch({
      type: "OtpSendFail",
      payload: error.response.data.message,
    });
    toast.error(
      error.response.data.message || "Login failed. Please try again."
    );
  }
};
export const SentOtp = (email, phoneNumber) => async (dispatch) => {
  try {
    dispatch({ type: "OtpSendRequest" });

    const { data } = await axios.post(
      `${server}/sentotp`,
      { email, phoneNumber },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({ type: "OtpSendSuccess", payload: data });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "OtpSendFail",
      payload: error.response.data.message,
    });
    toast.error(
      error.response.data.message || "Login failed. Please try again."
    );
  }
};
export const AuthenticateCompanyOtp =
  (email, otp, navigate) => async (dispatch) => {
    try {
      dispatch({ type: "OtpAuthenticateRequest" });

      const { data } = await axios.post(
        `${server}/verifyOtp`,
        { email, otp },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      localStorage.setItem("authToken", data.token); // Store token in localStorage
      setTimeout(() => {
        localStorage.removeItem("resetEmail"); // Remove email from localStorage after 20 minutes
      }, 20 * 60 * 1000);

      dispatch({ type: "OtpAuthenticateSuccess", payload: data });
      toast.success(data.message);
      navigate("/userDashboard"); // Navigate after successful OTP authentication
    } catch (error) {
      dispatch({
        type: "OtpAuthenticateFail",
        payload: error.response?.data?.message || "Authentication failed",
      });
      toast.error(
        error.response?.data?.message || "Login failed. Please try again."
      );
    }
  };

export const getmyclient = () => async (dispatch) => {
  try {
    dispatch({ type: "getmyprofileRequest" });

    const token = getAuthToken(); // Assume you have a function to get the auth token
    const { data } = await axios.get(`${server}/myprofile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    // Assuming `data.user` means a company user and `data.individual` refers to an individual
    const profile = data.user || data.individual;
    if (profile) {
      dispatch({ type: "getmyprofileSuccess", payload: profile });
      return profile; // Return the profile to be used elsewhere
    } else {
      throw new Error("User profile not found");
    }
  } catch (error) {
    console.log("Error fetching profile:", error);
    dispatch({
      type: "getmyprofileFail",
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};
export const getmyadmin = () => async (dispatch) => {
  try {
    dispatch({ type: "getmyadminprofileRequest" });

    const token = getAuthToken(); // Assume you have a function to get the auth token
    const { data } = await axios.get(`${server}/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    // Assuming `data.user` means a company user and `data.individual` refers to an individual
    const profile = data.user || data.individual;
    if (profile) {
      dispatch({ type: "getmyadminprofileSuccess", payload: profile });
      return profile; // Return the profile to be used elsewhere
    } else {
      throw new Error("User profile not found");
    }
  } catch (error) {
    console.log("Error fetching profile:", error);
    dispatch({
      type: "getmyadminprofileFail",
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};

export const helpdesk = (name, email, contact, query) => async (dispatch) => {
  try {
    dispatch({ type: "HelpDeskRequest" });

    const { data } = await axios.post(
      `${server}/createhelp`,
      { name, email, contact, query },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({ type: "HelpDeskSuccess", payload: data });
    console.log(data);
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "HelpDeskFail",
      payload: error.response.data.message,
    });
    toast.error(
      error.response.data.message ||
        "Sending Query isx failed. Please try again."
    );
  }
};

export const getallEvents = () => async (dispatch) => {
  try {
    dispatch({ type: "GetEventRequest" });

    const { data } = await axios.get(`${server}/getevents`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    dispatch({ type: "GetEventSuccess", payload: data });
    toast.success(data.message);

    // Return data so it can be used in the component
    return data.Events; // Assuming your API response has an `events` field
  } catch (error) {
    dispatch({
      type: "GetEventFail",
      payload: error.response.data.message,
    });
    toast.error(
      error.response.data.message || "Fetching events failed. Please try again."
    );

    // Return an empty array in case of error
    return [];
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    console.log("id", id);

    dispatch({ type: "DeleteEventRequest" });

    // Sending the `id` in the URL
    const { data } = await axios.delete(`${server}/deleteEvent/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    dispatch({ type: "DeleteEventSuccess", payload: data });
    toast.success(data.message);

    // Return data so it can be used in the component
    return data.Events; // Assuming your API response has an `events` field
  } catch (error) {
    dispatch({
      type: "DeleteEventFail",
      payload: error.response?.data?.message,
    });
    toast.error(
      error.response?.data?.message ||
        "Deleting event failed. Please try again."
    );

    // Return an empty array or null to handle errors gracefully in the component
    return null;
  }
};

export const getHelp = () => async (dispatch) => {
  try {
    dispatch({ type: "GetHelpRequest" });

    const { data } = await axios.get(`${server}/gethelp`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    dispatch({ type: "GetHelpSuccess", payload: data });
    toast.success(data.message);
    console.log(data);
    return data.help;

    // Return data so it can be used in the component
    return data.Events; // Assuming your API response has an `events` field
  } catch (error) {
    dispatch({
      type: "GetHelpFail",
      payload: error.response.data.message,
    });
    toast.error(
      error.response.data.message || "Fetching events failed. Please try again."
    );

    // Return an empty array in case of error
    return [];
  }
};

export const CreateEvent = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "CreateEVentRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/createEvent`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    dispatch({ type: "CreateEventSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "CreateEventFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const updateEvent = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: "UpdateEventRequest" });
    const token = getAuthToken();

    // Sending a PUT request with id and updated data (title and date)
    const { data } = await axios.put(
      `${server}/updateEvent`,
      { id, ...updatedData }, // id, title, and date are sent in a single data object
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch({ type: "UpdateEventSuccess", payload: data.message });
    toast.success(data.message);
    console.log(data);
  } catch (error) {
    dispatch({
      type: "UpdateEventFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export const getAllNotifications = () => async (dispatch) => {
  try {
    dispatch({ type: "GetNotificationRequest" });

    const { data } = await axios.get(`${server}/getallNotification`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);

    dispatch({ type: "GetNotificationSuccess", payload: data.notifications }); // Assuming your API response has a `notifications` field
    // toast.success(data.message);

    return data.notifications || []; // Ensure it returns an array
  } catch (error) {
    dispatch({
      type: "GetNotificationFail",
      payload: error.response?.data?.message || "An error occurred",
    });

    return []; // Return an empty array in case of error
  }
};

export const createNotification = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "CreateNotificationRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/createNotication`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "CreateNotificationSuccess", payload: data.message });
    toast.success(data.message);
    return data.companyId;
  } catch (error) {
    dispatch({
      type: "CreateNotificationFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export const deleteNotification = (id) => async (dispatch) => {
  try {
    dispatch({ type: "DeleteNotificationRequest" });
    const token = getAuthToken();
    const { data } = await axios.delete(`${server}/deleteNotification/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "DeleteNotificationSuccess", payload: data.message });
    toast.success(data.message);
    console.log(data);
  } catch (error) {
    dispatch({
      type: "DeleteNotificationFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const Addguest = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "AddGuestRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/addguest`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(data);
    dispatch({ type: "AddGuestSuccess", payload: data.message });
    navigate("/userDashboard");
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "AddGuestFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const getClient = () => async (dispatch) => {
  try {
    dispatch({ type: "GetAllUserRequest" });
    const token = getAuthToken();
    const { data } = await axios.get(`${server}/getallclient`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    dispatch({ type: "GetAllUserSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "GetAllUserFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const getGuest = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GetAllUserRequest" });
    const token = getAuthToken();
    const { data } = await axios.get(`${server}/getguest/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data.guest);

    dispatch({ type: "GetAllUserSuccess", payload: data.message });
    toast.success(data.message);
    return data.guest;
  } catch (error) {
    dispatch({
      type: "GetAllUserFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

//Newssss\\

export const createNews = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "CreateNewsRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/createnews`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "CreateNewsSuccess", payload: data.message });
    toast.success(data.message);
    console.log("news", data);

    return data;
  } catch (error) {
    dispatch({
      type: "CreateNewsFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export const getAllNews = () => async (dispatch) => {
  try {
    dispatch({ type: "GetAllNewsRequest" });

    const { data } = await axios.get(`${server}/getallnews`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log(data);

    dispatch({ type: "GetAllNewsSuccess", payload: data.getallnews }); // Assuming your API response has a `notifications` field
    toast.success(data.message);

    return data.news || []; // Ensure it returns an array
  } catch (error) {
    dispatch({
      type: "GetAllNewsFail",
      payload: error.response?.data?.message || "An error occurred",
    });
    toast.error(
      error.response?.data?.message ||
        "Fetching notifications failed. Please try again."
    );

    return []; // Return an empty array in case of error
  }
};

export const deleteNews = (id) => async (dispatch) => {
  try {
    dispatch({ type: "DeleteNewsRequest" });
    const token = getAuthToken();
    const { data } = await axios.delete(`${server}/deleteNews/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "DeleteNewsSuccess", payload: data.message });
    toast.success(data.message);
    console.log(data);
  } catch (error) {
    dispatch({
      type: "DeleteNewsFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export const updateDirectors = (formData, companyid) => async (dispatch) => {
  try {
    dispatch({ type: "UpdateDirectorsRequest" });
    const token = getAuthToken();
    const { data } = await axios.put(
      `${server}/updateDirector/${companyid}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UpdateDirectorsSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "UpdateDirectorsFail",
      payload: error.response?.data?.message || "Error updating director",
    });
    toast.error(error.response?.data?.message || "Error updating director");
  }
};

export const updateNominee = (formData, companyid) => async (dispatch) => {
  try {
    dispatch({ type: "UpdateNomineeRequest" });
    const token = getAuthToken();
    const { data } = await axios.put(
      `${server}/updateNominee/${companyid}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UpdateNomineeSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "UpdateNomineeFail",
      payload: error.response?.data?.message || "Error updating director",
    });
    toast.error(error.response?.data?.message || "Error updating director");
  }
};

export const updateSpvDirectors = (formData, companyid) => async (dispatch) => {
  try {
    dispatch({ type: "UpdateSpvDirectorsRequest" });
    const token = getAuthToken();
    const { data } = await axios.put(
      `${server}/updateSpvDirector/${companyid}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UpdateSpvDirectorsSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "UpdateSpvDirectorsFail",
      payload: error.response?.data?.message || "Error updating director",
    });
    toast.error(error.response?.data?.message || "Error updating director");
  }
};

export const updateParentDocuments = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: "UpdateDocumentsRequest" });
    const token = getAuthToken();
    const { data } = await axios.put(`${server}/editDoucment/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "UpdateDocumentsSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "UpdatedDocumentsFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

export const creatBAnkaccount = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: "CreateBankRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/create-bank-account/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "CreateBankSuccess", payload: data.message });
    toast.success(data.message);
    console.log("news", data);

    return data;
  } catch (error) {
    dispatch({
      type: "CreateBankFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const createTraining = (formData, id, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "CreateTrainingRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/uploadTrainingForm/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "CreateTrainingSuccess", payload: data.message });
    toast.success(data.message);
    navigate("/userDashboard");
    return data;
  } catch (error) {
    dispatch({
      type: "CreateTrainingFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const creatIndividualBAnkaccount =
  (formData, id) => async (dispatch) => {
    try {
      dispatch({ type: "CreateIndividualBankRequest" });
      const token = getAuthToken();
      const { data } = await axios.post(
        `${server}/create-individual-bank-account/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "CreateIndividualBankSuccess", payload: data.message });
      toast.success(data.message);
      console.log("news", data);

      return data;
    } catch (error) {
      dispatch({
        type: "CreateIndividualBankFail",
        payload: error.response.data.message,
      });
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

// idividualsssssssssssss
export const createIndividual = (name, email) => async (dispatch) => {
  try {
    dispatch({ type: "CreateIndividualRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/createindividual`,
      { name, email },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "CreateIndividualSuccess", payload: data.message });
    toast.success(data.message);
    console.log("news", data);

    return data.individual._id;
  } catch (error) {
    dispatch({
      type: "CreateIndividualFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const addindividualdocs = (formdata, id) => async (dispatch) => {
  try {
    dispatch({ type: "CreateIndividualdocsRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/individualDocuments/${id}`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "CreateIndividualdocsSuccess", payload: data.message });
    toast.success(data.message);
    console.log("news", data);

    return data.individual._id;
  } catch (error) {
    dispatch({
      type: "CreateIndividualdocsFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const addindividualnominees = (formdata, id) => async (dispatch) => {
  try {
    dispatch({ type: "CreateIndividualnomineesRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/addIndividualNominnee/${id}`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: "CreateIndividualnomineesSuccess",
      payload: data.message,
    });
    toast.success(data.message);
    console.log("news", data);

    return data.individual._id;
  } catch (error) {
    dispatch({
      type: "CreateIndividualnomineesFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export const addindividualcontract = (formdata, id) => async (dispatch) => {
  try {
    dispatch({ type: "CreateIndividualContractRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/addIndividualContract/${id}`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: "CreateIndividualContractSuccess",
      payload: data.message,
    });
    toast.success(data.message);
    console.log("news", data);

    return data.individual._id;
  } catch (error) {
    dispatch({
      type: "CreateIndividualContractFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const editIndividual = (formData, id) => async (dispatch) => {
  try {
    console.log("iddddddddddddddddddddd", id);

    dispatch({ type: "EditIndividualRequest" });
    const token = getAuthToken();
    const { data } = await axios.put(
      `${server}/updateIndividual/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "EditIndividualSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "EditIndividualFail",
      payload: error.response?.data?.message || "Error updating director",
    });
    toast.error(error.response?.data?.message || "Error updating director");
  }
};

export const createRefund = (formData, id, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "CreateRefundBankRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/createRefund`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: "CreateRefundBankSuccess", payload: data.message });
    toast.success(data.message);
    navigate("/userDashboard");
    return data;
  } catch (error) {
    dispatch({
      type: "CreateRefundBankFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export const createEducation = (formData, id, navigate) => async (dispatch) => {
  try {
    dispatch({ type: "CreateEducationRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/createEducation/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: "CreateEducationSuccess", payload: data.message });
    toast.success(data.message);
    console.log("data", data);

    navigate("/userDashboard");
    return data;
  } catch (error) {
    dispatch({
      type: "CreateEducationFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const createDirectorEducation =
  (formData, id, navigate) => async (dispatch) => {
    try {
      dispatch({ type: "CreateEducationRequest" });
      const token = getAuthToken();
      const { data } = await axios.post(
        `${server}/createDirectorEducation/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch({ type: "CreateEducationSuccess", payload: data.message });
      toast.success(data.message);
      console.log("data", data);

      navigate("/userDashboard");
      return data;
    } catch (error) {
      dispatch({
        type: "CreateEducationFail",
        payload: error.response.data.message,
      });
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

//nda........................................................
export const createNDa = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "CreateNDaRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/addNda`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: "CreateNDaSuccess", payload: data.message });
    toast.success(data.message);
    console.log("data", data);
  } catch (error) {
    dispatch({
      type: "CreateNDaFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

//create Charity.....................

export const createCharity = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "CreateCharityRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(`${server}/createcharity`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: "CreateCharityaSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "CreateCharityaFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const getallCharity = () => async (dispatch) => {
  try {
    dispatch({ type: "getCharityRequest" });
    const token = getAuthToken();
    const { data } = await axios.get(`${server}/getCharity`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "getCharityaSuccess", payload: data.message });
    toast.success(data.message);
    return data;
  } catch (error) {
    dispatch({
      type: "getCharityaFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};
export const getsingleCharity = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getsingleCharityRequest" });
    const token = getAuthToken();
    const { data } = await axios.get(`${server}/getsingleCharity/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "getsingleCharityaSuccess", payload: data.message });
    toast.success(data.message);
    return data;
  } catch (error) {
    dispatch({
      type: "getsingleCharityaFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export const AddCharity = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: "AddCharityRequest" });
    const token = getAuthToken();
    const { data } = await axios.post(
      `${server}/addimageCharity/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: "AddCharityaSuccess", payload: data.message });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: "AddCharityaFail",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export const createlogactivity =
  (companyId, individualId, action, description) => async (dispatch) => {
    try {
      dispatch({ type: "CreateLogActivityRequest" });
      const token = getAuthToken();
      const { data } = await axios.post(
        `${server}/createclientactivity`,
        { companyId, individualId, action, description },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "CreateLogActivitySuccess", payload: data.message });
    } catch (error) {
      dispatch({
        type: "CreateLogactivityFail",
        payload: error.response.data.message,
      });
      toast.error(error.response.data.message);
      console.log(error);
    }
  };
// Action (companyaction.js)

export const createTrainingmodule = (formData) => async (dispatch) => {
  try {
    // Use axios to send the POST request to your backend
    const response = await axios.post(
      `${server}/createTrainingModule`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;

    if (data.success) {
      dispatch({
        type: "CREATE_TRAINING_MODULE",
        payload: data.module,
      });
      alert("Training Module Created Successfully");
    } else {
      alert("Error creating training module");
    }
  } catch (error) {
    console.error("Error creating training module:", error);
    alert("Failed to create training module");
  }
};

export const mytraining = (id) => async (dispatch) => {
  try {
    dispatch({ type: "getmyTrainingRequest" });

    const token = getAuthToken(); // Ensure this function exists to retrieve the auth token

    // Make the API request
    const { data } = await axios.get(`${server}/mysingletraining/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
    console.log("ddd",data);
    

    // Dispatch the success action with the fetched data
    dispatch({ type: "getmyTrainingSuccess", payload: data });

    // Optionally return the data for additional handling if needed
    return data;
  } catch (error) {
    console.error("Error fetching training module:", error);

    dispatch({
      type: "getmyTrainingFail",
      payload: error.response?.data?.message || "Failed to fetch training module.",
    });
  }
};
