import React, { useEffect, useState } from 'react'
import "./ClientTraining.css"
import { useDispatch } from 'react-redux'
import { mytraining } from '../../redux/action/companyaction';
import { useParams } from 'react-router-dom';
import logo from "../logo-vpvv.png"


const ClientTraining = () => {
    const [ training,setTraining] = useState({})
    const dispatch = useDispatch();
    const { id } = useParams(); // Get the id from the URL params
    useEffect(() => {
        console.log("id",id);
        
        const fetchData = async () => {
            const result = await dispatch(mytraining(id))
            console.log(result)
            setTraining(result?.
                training
                )
        }
        fetchData()
    },[dispatch,id])
  return (
      <>
          <div className="clienttrainingall">
              <img style={{marginTop:"2rem"}} src={logo } alt="q" />
              <h2 style={{ color: 'white', }}>Training Module</h2>
              {training ? <>
                
                <div className="trainingstatus">
                      <h4>YOUR TRAINING MODULE HAS BEEN RELEASED AND THE PORTAL IS GOING INTO HYBERNATION MODE FOR SYNCHRONIZATION.
                      </h4>
                      <h4>
                      YOUR TRAINING MODULE DATA WILL BE SYNCHRONIZED WITH THE DATABASE AND UPDATED IN THE PORTAL SHORTLY.                          
                      </h4>
                    
                      </div>
                  <div className="mytrainingbox">
                     
                  <div className="mytraining">
                      <h4>Company Name</h4>
                          <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Trainee1</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Trainee2</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>APO/FPO:</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Type</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Zone</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Assigned Uniform:</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Trainer Assigned:</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Trainer Name:</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Trainer Designation:</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Commands</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Sector</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>
                  <div className="mytraining">
                      <h4>Level</h4>
                      <h4>XXXXXXXXXXXXXXXXX</h4>
                  </div>

              </div>
              </> : <div>
                      <div className="notraining">
                      <h4>
YOU HAVE NOT FILED THE NSTM-I FORM AS PER THE DATABASE.
PLEASE FILL UP THE TRAINING FORM AND UPLOAD FOR FURTHER PROCESSING.</h4>
                      </div>
                </div>}
      </div>
      </>
  )
}

export default ClientTraining
